import React from "react";
import PropTypes from "prop-types";
import { Spin, Row } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const BoxLoader = ({ size = 32, height = "30vh", className = "" }) => {
  return (
    <Row
      className={className}
      justify="center"
      align="middle"
      style={{ height, width: "100%" }}
    >
      <Spin
        indicator={<Loading3QuartersOutlined style={{ fontSize: size }} spin />}
      />
    </Row>
  );
};

BoxLoader.propTypes = {
  size: PropTypes.number,
};

export default BoxLoader;
