import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const InlineSpinner = ({
  className = "",
  fontSize = 32,
  color = "#003399",
}) => {
  return (
    <Spin
      className={className}
      indicator={<Loading3QuartersOutlined style={{ fontSize, color }} spin />}
    />
  );
};

InlineSpinner.propTypes = {
  fontSize: PropTypes.number,
};

export default InlineSpinner;
